<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/raceManagement/ibcHomeManagement'}">IBC赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item>成绩设置</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>成绩信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="届" prop="ibcSession">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.ibcSession"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcSession"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="季度" prop="ibcSeason">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.ibcSeason"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcSeason"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="轮次" prop="ibcRound">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.ibcRound"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcRound"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="enrollmentLevel">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.enrollmentLevel"
                clearable
                @change="screeningChangeed()"
              >
                <el-option
                  v-for="item in dict_sspcnGroup"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
                @input="screeningChangeed()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
                @input="screeningChangeed()"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="国籍" prop="country">
              <el-input
                v-model="queryForm.country"
                placeholder="请输入"
                @input="screeningChangeed()"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="晋级状态" prop="promotion">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.promotion"
                @change="screeningChangeed()"
                clearable
              >
                <el-option
                  v-for="item in dict_isPromotion"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 成绩信息列表 -->
    <el-card style="margin-top: 15px" v-if="isShowList === true">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="14">
          <span
            style="display: -moz-inline-box;
              display: inline-block;
              width: 140px;
            "
            >IBC成绩实时查询</span
          >
          <span style="color: blue; font-size: 14px" v-if="queryForm.ibcRound === '1'">{{
            "已晋级" + PromotionNumData.promotionOne + "人"
          }}</span>
          <!-- + "未比赛" + (PromotionNumData.promotionTwo -  PromotionNumData.noPromotion) + "人" -->
          <span style="color: blue; font-size: 14px" v-else>{{
            "已晋级" + PromotionNumData.promotionOne + "人"
          }}</span>
          <!-- + "未晋级" + (PromotionNumData.promotionTwo -  PromotionNumData.promotionOne) + "人" -->
        </el-col>
        <el-col  :span="10" style="text-align: right;">
         <div class="div_algin_right">
            <el-button type="primary" :disabled=!isAllowSetList @click="setTheListClick">公布成绩</el-button>
            <!-- <el-button type="primary" @click="batchExportGrades"
              >导出成绩</el-button
            > -->
            <el-button type="primary" @click="batchSettingPromotion"
              v-if="isBatchOperation === true">确认晋级</el-button
            >
            <el-button type="danger" @click="cancelBatchSettingPromotion"
              v-if="isBatchOperation === true">取消晋级操作</el-button
            >
            <el-button type="primary" @click="batchSettingPromotion"
              v-if="isBatchOperation === false && queryForm.ibcRound !== '1'">批量设置晋级</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        @selection-change="PromotionSelectionChange">
        border
        stripe
      >
        <el-table-column
          type="selection"
           width="70px"
           header-align="center"
           align="center"
           v-if="isBatchOperation === true"
           >
        </el-table-column>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column
          label="性别"
          width="80px"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column label="国籍" prop="country" width="100px"></el-table-column>
        <el-table-column label="学校" prop="schoolName" width="200px"></el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column label="Round 1" prop="scoreOne" v-if="queryForm.ibcRound === '1' ||queryForm.ibcRound === '2' "></el-table-column>
        <el-table-column label="Round 2" prop="scoreTwo" v-if="queryForm.ibcRound === '2'"></el-table-column>
        <el-table-column label="Round 1+2" prop="total" v-if="queryForm.ibcRound === '2'"></el-table-column>
        <el-table-column label="Round 3" prop="scoreThree" v-else-if="queryForm.ibcRound === '3'"></el-table-column>
        <el-table-column label="Round 4" prop="scoreFour" v-else-if="queryForm.ibcRound === '4'"></el-table-column>
        <el-table-column label="Round 5" prop="nscoreFive" v-else-if="queryForm.ibcRound === '5'"></el-table-column>
         <el-table-column label="晋级状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.promotion === 0" size="mini"
              >未晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.promotion === 1"
              size="mini"
              >晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.promotion === -1"
              size="warning"
              >未设置</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px" v-if="queryForm.ibcRound !== '1'">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="showEditDialog(scope.row)"
              >晋级修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
        <!-- 修改成绩的dialog -->
    <el-dialog
      title="修改成绩信息"
      :visible.sync="editScoreDialogVisible"
      width="30%"
    >
      <el-form
        :model="editScoreForm"
        label-width="80px"
        ref="editScoreFormRef"
        :rules="editScoreFormRules"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="editScoreForm.userName"
            placeholder="请输入"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="晋级资格"
          prop="promotion"
        >
          <el-radio-group v-model="editScoreForm.promotion">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editScoreDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editScore">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getIbcMatchPromotionList, getIbcPromotionNum, updateIbcMatchPromotionList, saveIsPromotion, queryIbcSeason } from '@/http/api'
export default {
  data () {
    return {
      isAllowSetList: true, // 是否允许发布成绩
      isShowList: false,
      editScoreDialogVisible: false,
      editScoreFormRules: {
        promotion: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      PromotionNumData: {
        promotionOne: 0,
        promotionTwo: 0
      },
      editScoreForm: {},
      isBatchOperation: false, // 是否设置批量晋级
      total: 0,
      dataList: [],
      selectPromotionIds: [],
      queryForm: {
        grade: '',
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$ibcManager.ibcSeason,
        ibcRound: '2',
        pageNum: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        country: '',
        promotion: ''
      },
      dict_gender: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.dict_gender = value }),
      dict_grade: this.$userInfo.getGradeData(),
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      dict_isPromotion: [
        {
          dictCode: 0,
          dictValue: 0,
          dictLabel: '未晋级'
        }, {
          dictCode: 1,
          dictValue: 1,
          dictLabel: '已晋级'
        }
      ]
    }
  },
  created () {
    if (this.$route.query.queryFormData) {
      this.queryForm = this.$route.query.queryFormData
    }
    this.getQueryIbcSeason()
  },
  methods: {
    getQueryIbcSeason () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        this.queryForm = { ...this.queryForm, ...newObj }
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    screeningChangeed () {
      if (this.queryForm.grade.length > 0 || this.queryForm.userName.length > 0 || this.queryForm.phone.length > 0 || this.queryForm.country.length > 0 || this.queryForm.promotion.length > 0) {
        this.isAllowSetList = false
      } else {
        this.isAllowSetList = true
      }
    },
    // 获取成绩列表数据
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getIbcMatchPromotionList(this.queryForm).then((res) => {
        this.getProgressStatus()
        this.isShowList = true
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取晋级未晋级人数
    getProgressStatus () {
      getIbcPromotionNum(this.queryForm).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.PromotionNumData = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 筛选条件改变隐藏底部列表
    selectChanged () {
      this.isShowList = false
    },
    // 重置搜索内容
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      console.log('data', data.enrollmentLevel)
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === data.enrollmentLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // // 批量导出成绩
    // batchExportGrades () {
    // },
    // 显示晋级弹窗
    showEditDialog (row) {
      this.editScoreForm.userName = row.userName
      this.editScoreForm.id = [row.id]
      this.editScoreDialogVisible = true
    },
    // 单个修改晋级
    editScore () {
      this.savePromotionInfoData(this.editScoreForm)
    },
    // 批量设置晋级
    batchSettingPromotion () {
      if (this.isBatchOperation === false) {
        this.isBatchOperation = true
      } else {
        if (this.selectPromotionIds.length === 0) {
          return this.$message.error('设置晋级考生不可为空')
        }
        this.$confirm('是否确认并保存当前晋级信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          var setPromotionForm = {}
          setPromotionForm.id = this.selectPromotionIds
          setPromotionForm.promotion = 1
          this.savePromotionInfoData(setPromotionForm)
        }).catch(() => {
        })
      }
    },
    PromotionSelectionChange (val) {
      console.log('val', val)
      this.selectPromotionIds = []
      for (let index = 0; index < val.length; index++) {
        const element = val[index]
        this.selectPromotionIds.push(element.id)
      }
    },
    // 确认晋级名单
    savePromotionInfoData (formData) {
      updateIbcMatchPromotionList(formData).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.editScoreDialogVisible = false
        this.isBatchOperation = false
        this.$message.success('保存成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 取消晋级操作
    cancelBatchSettingPromotion () {
      // this.$confirm('是否取消当前晋级操作?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      this.selectPromotionIds = []
      this.isBatchOperation = false
      // }).catch(() => {
      // })
    },
    // 发布成绩
    setTheListClick () {
      this.$confirm('确认后成绩将公布，是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.setList()
      }).catch(() => {
      })
    },
    setList () {
      var queryFormData = {}
      queryFormData.ibcSession = Number(this.queryForm.ibcSession)
      queryFormData.ibcSeason = Number(this.queryForm.ibcSeason)
      queryFormData.ibcRound = Number(this.queryForm.ibcRound)
      queryFormData.activityType = this.$chnEngStatusCode.ibcActivityType
      saveIsPromotion(queryFormData).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.$message.success('成绩发布成功！')
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "全选";
  margin-left: 5px;
}
</style>
